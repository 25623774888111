<script setup>
const form = ref(null);
const loading = ref(false);
const formData = ref({
  "your-name": "",
  "your-phone": "",
  "your-car": "",
  "your-email": "",
});
const message = ref("");
const sendForm = async () => {
  loading.value = true;
  message.value = "";
  form.value.validate().then(async ({valid, errors}) => {
    if (valid) {
      let data = new FormData();
      for (const dataKey in formData.value) {
        data.append(dataKey, formData.value[dataKey].toString());
      }

      const res = await wpSendForm("8778", data);
      if (res.invalid_fields.length > 0) {
        for (const field of res.invalid_fields) {
          form.value.items.indexOf();
          const find = form.value.items.find((f) => f.id == field.field);
          //find.isValid = false;
          find.errorMessages.push(field.message);
        }
      }
      if (res.status == 'mail_sent') {
        message.value = res.message;
        form.value.reset();
      }
    }
  });
  setTimeout(() => {
    loading.value = false;
  }, 1000);
};
const validateEmail = (email) => {
  return String(email)
      .toLowerCase()
      .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};
const validatePhone = (phone) => {
  let phone_regex = new RegExp(
      "^(?:(?:(\\+?972|\\(\\+?972\\)|\\+?\\(972\\))(?:\\s|\\.|-)?([1-9]\\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\\s|\\.|-)?([^0\\D]{1}\\d{2}(?:\\s|\\.|-)?\\d{4})$"
  );
  return phone_regex.test(phone)
}
</script>

<template>
  <v-form
      validate-on="submit"
      ref="form"
      @submit.prevent="sendForm"
      action="/#wpcf7-f8778-o2"
      method="post"
      class="wpcf7-form init"
      aria-label="Contact form"
  >
    <div class="form_input">
      <v-text-field
          density="compact"
          variant="underlined"
          validate-on="submit"
          required
          label="*שם"
          :rules="[(v) => !!v || 'שם חובה..']"
          v-model="formData['your-name']"
          name="you-name"
      />
    </div>
    <div class="form_input">
      <v-text-field
          density="compact"
          variant="underlined"
          required
          label="*טלפון"
          :rules="[
                (v) => !!v || 'טלפון חובה..',
                (v) => v && validatePhone(v) || 'טלפון לא תקין'
                ]"
          validate-on="submit"
          v-model="formData['your-phone']"
          name="you-phone"
      />
    </div>
    <div class="form_input">
      <v-text-field
          density="compact"
          variant="underlined"
          required
          label="*מה דגם הרכב שלך?"
          :rules="[(v) => !!v || 'מה דגם הרכב שלך?...']"
          validate-on="submit"
          v-model="formData['your-car']"
          name="you-car"
      />
    </div>
    <div class="form_input">
      <v-text-field
          density="compact"
          variant="underlined"
          label="אימייל..."
          validate-on="submit"
          :rules="[v => {
              if(v) return validateEmail(v) || 'אימייל לא תקין';
              return true;
              }]"
          v-model="formData['your-email']"
          name="you-email"
      />
    </div>
    <div class="form_submit bt_red max-w100">
      <v-btn type="submit" size="x-large" class="font-bold" :loading="loading">שלח</v-btn>
    </div>
    <div class="form_message" v-if="message">{{ message }}</div>
  </v-form>
</template>

<style scoped lang="scss">


</style>
